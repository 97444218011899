import React from 'react';
import AppContext from '../../context/AppContext';
import SchedulesService from '../../services/SchedulesService';
import {Toast} from 'primereact/toast';
import {ScrollPanel} from 'primereact/scrollpanel';
import {Panel, PanelHeaderTemplateOptions} from 'primereact/panel';
import {Card} from 'primereact/card';
import {QueryParameter, Run, ScheduleEntry, Stop} from 'two-core';
import {DateTime} from 'luxon';
import formats from '../../config/formats';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {NavLink} from 'react-router-dom';
import './ScheduleComponent.scss';
import RunsService from '../../services/RunsService';
import StopsService from '../../services/StopsService';
import {Skeleton} from 'primereact/skeleton';
import {ProgressSpinner} from 'primereact/progressspinner';
import {AppToolbar} from '../AppFrame/AppToolbar/AppToolbar';
import {ToastService} from 'two-app-ui';

interface State {
  schedules: ScheduleEntry[];
  runs: Run[];
  stops: Stop[];
  dates: Date[];
  loading: boolean;
}

export class ScheduleComponent extends React.Component<{}, State> {
  static contextType = AppContext;

  schedulesService?: SchedulesService;
  runsService?: RunsService;
  stopsService?: StopsService;
  toastService?: ToastService;
  appToolbarRef?: React.RefObject<AppToolbar>;

  toast: React.RefObject<Toast>;

  constructor(props = {}) {
    super(props);
    this.state = {
      schedules: [],
      runs: [],
      stops: [],
      dates: [],
      loading: false,
    };

    this.toast = React.createRef();
    this.initAppToolbar = this.initAppToolbar.bind(this);
  }

  async componentDidMount() {
    this.schedulesService = this.context.schedulesService;
    this.runsService = this.context.runsService;
    this.stopsService = this.context.stopsService;
    this.toastService = this.context.toastService;
    this.appToolbarRef = this.context.appToolbarRef;

    this.loadData();
    this.initAppToolbar();
  }

  async loadData() {
    const filters: string[] = [];
    const sortBy: string[] = [];

    const vehicleId = localStorage.getItem('vehicleId');

    filters.push(
      JSON.stringify({
        field: 'vehicle_id',
        value: vehicleId,
      }),
      JSON.stringify({
        field: 'run.stage',
        condition: '<>',
        value: 'Done',
      })
    );

    sortBy.push(
      JSON.stringify({
        field: 'line_up',
        direction: 'ASC',
      })
    );

    const params: QueryParameter = {
      orderBys: sortBy,
      aggregate: true,
      filters: filters,
    };

    this.setState({loading: true});
    this.schedulesService
      ?.getSchedules(params)
      .then(data => {
        const dataRecords = ((data?.records as ScheduleEntry[]) ?? []).filter(se => se !== null);

        const runsIds: number[] = [];
        const dateList: Date[] = [];
        dataRecords.map(se => {
          const startDate = se.start_at;
          if (dateList.indexOf(startDate) === -1) dateList.push(startDate);
          if (se.run_id) {
            runsIds.push(se.run_id);
          }
        });

        this.loadRuns(runsIds);
        this.loadStops(runsIds);

        dateList.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
        this.setState({
          schedules: dataRecords,
          dates: dateList,
          loading: false,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
        console.error(error);
      });
  }

  loadRuns(ids: number[]) {
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: ids,
        condition: 'in',
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    this.runsService
      ?.getRuns(params)
      .then(data => {
        const dataRecords = (data?.records as Run[]) ?? [];

        this.setState({
          runs: dataRecords,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
      });
  }

  loadStops(runsIds: number[]) {
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'run_id',
        value: runsIds,
        condition: 'in',
      })
    );

    const params: QueryParameter = {
      filters: filters,
      aggregate: true,
    };

    this.stopsService
      ?.getStops(params)
      .then(data => {
        const dataRecords = (data?.records as Stop[]) ?? [];

        this.setState({
          stops: dataRecords,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, records load failed, please try again.');
      });
  }

  initAppToolbar() {
    const title = 'Schedule';
    const backTitle = 'Logout';
    const backUrl = '/';
    this.appToolbarRef?.current?.initAppToolbar({
      title,
      backUrl,
      backTitle,
    });
  }

  isToday = (someDate: Date) => {
    const today = new Date();
    const date = new Date(someDate);
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  isPastDate = (someDate: Date) => {
    const today = new Date();
    today.setDate(today.getDate() - 1);
    today.setHours(0, 0, 0, 0);

    const date = new Date(someDate);
    date.setHours(0, 0, 0, 0);
    return date < today;
  };

  doneStops = (run: Run) => {
    const stopIds = run.stops?.map(s => s?.id ?? 0) ?? [];
    const doneStops: Stop[] = [];

    const stops =
      this.state.stops?.filter(s => s !== null && stopIds.includes(parseInt((s?.id ?? 0).toString()))) ?? [];

    stops.map(stop => {
      const notexecutedTasks = stop?.tasks?.filter(t => t !== null && t.executed_on === null) ?? [];
      if (notexecutedTasks.length === 0) {
        doneStops.push(stop);
      }
    });

    return doneStops.length;
  };

  render() {
    const {dates, runs, loading} = this.state;

    return (
      <>
        {loading && (
          <>
            <div className="overlay">
              <ProgressSpinner className="overlay-spinner" />
            </div>
          </>
        )}
        <ScrollPanel id="schedule_component">
          {dates &&
            dates.map((date, index) => {
              const schedules = this.state.schedules
                .filter(s => new Date(s.start_at).getTime() === new Date(date).getTime())
                .sort((a, b) => new Date(a.start_at).getTime() - new Date(b.start_at).getTime());

              const template = (options: PanelHeaderTemplateOptions) => {
                const toggleIcon = (
                  <FontAwesomeIcon
                    icon={options.collapsed ? ['fal', 'chevron-down'] : ['fal', 'chevron-up']}
                    size={'2x'}
                  />
                );

                const className = `${options.className}`;
                const titleClassName = `${options.titleClassName}`;

                const isToday = this.isToday(date);

                return (
                  <>
                    <div className={className + ' p-d-flex'}>
                      <div className="p-col-3 p-p-0">
                        <span className={titleClassName + ' p-ai-center schedule-title'}>
                          {DateTime.fromJSDate(new Date(date)).toFormat(formats.date)}
                        </span>
                      </div>
                      <div className="p-d-flex p-col-6 p-p-0 p-ai-center p-jc-center">
                        <span className={titleClassName + ' schedule-title'}>
                          {DateTime.fromJSDate(new Date(date)).toFormat(formats.weekday)}
                        </span>
                      </div>
                      <div className="p-d-flex p-col-3 p-p-0 p-ai-center p-jc-end">
                        {isToday && <span className={titleClassName + ' p-mr-2 schedule-title'}>TODAY</span>}
                        <button className={'p-button p-button-sm'} onClick={options.onTogglerClick}>
                          <span className={'p-button-icon'}>{toggleIcon}</span>
                        </button>
                      </div>
                    </div>
                  </>
                );
              };

              let showRunPanelCount = 0;
              const panel = (
                <Panel
                  key={'day' + index + date.toString()}
                  className={index !== dates.length - 1 ? 'p-mb-2' : ''}
                  headerTemplate={template}
                  toggleable
                >
                  {schedules &&
                    schedules.map((schedule, index) => {
                      const cardMargin = index !== schedules.length - 1 ? 'p-mb-3' : '';

                      const run = runs.find(r => r.id?.toString() === schedule.run_id?.toString());
                      const runStops = run?.stops?.filter(s => s !== null) ?? [];
                      const doneRunStops = run ? this.doneStops(run) : 0;
                      const allRunStops = runStops.length;

                      const isPastDate = this.isPastDate(date);
                      if (!isPastDate || (isPastDate && doneRunStops !== allRunStops)) {
                        showRunPanelCount++;
                        const card =
                          schedule.type === 'run' ? (
                            run ? (
                              <NavLink to={'run/' + run?.id}>
                                <Card key={'schedule' + index + schedule?.id?.toString()} className={cardMargin}>
                                  <div className="p-d-flex p-flex-wrap">
                                    <div className="p-d-flex p-md-8 p-p-0 p-col-12">
                                      <strong className="p-mr-2 p-as-center">{run?.name ?? 'run unknown'}</strong>
                                    </div>
                                    <div className="p-d-flex p-md-4 p-p-0 p-col-12">
                                      <label className="p-mr-2 p-as-center">stops</label>
                                      <span>{`${doneRunStops}/${runStops.length}`}</span>
                                    </div>
                                  </div>
                                </Card>
                              </NavLink>
                            ) : (
                              <Card key={'schedule' + index + schedule?.id?.toString()} className={cardMargin}>
                                <div className="p-d-flex p-flex-wrap">
                                  <div className="p-col-12">
                                    <Skeleton width="100%" height="1.25rem" />
                                  </div>
                                </div>
                              </Card>
                            )
                          ) : (
                            <Card
                              key={'schedule' + index + schedule?.id?.toString()}
                              className={cardMargin + ' task-reminder'}
                            >
                              {schedule.description ?? ''}
                            </Card>
                          );
                        return card;
                      }
                      return;
                    })}
                </Panel>
              );

              if (showRunPanelCount > 0) {
                return panel;
              }
              return;
            })}
        </ScrollPanel>
      </>
    );
  }
}

export default ScheduleComponent;
